import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../SGRE-shared/services/api.service';
import { SharedCheckoutService } from '../../services/sharedcheckout.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { isNotNullable, isNotUndefined } from '@spartacus/core';
import { WindowRef } from '@spartacus/core';

declare let bootstrap: any;

@Component({
  selector: 'app-checkout-detail',
  templateUrl: './checkout-details.component.html',
  styleUrls: ['./checkout-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutDetailsComponent implements OnInit, OnDestroy {

  public modalInstance: any;
  companyVal: any;
  legalEntityObj: any;
  companyName: any;
  addresses: any;
  selectedLegalEntity: Observable<any>;
  selectedLegalEntitySession: any;
  purchaseOrderNumber: string = '';
  modalPurchaseOrderNumber: string = '';
  isSubmitAttempted: boolean;
  acceptTerms: any;
  flag: boolean = false;
  cartlegalEntity: any;
  cartpurchaseOrderNumber: any;
  componentActive: boolean = true;
  requesterOnly: boolean;
  requesterAndPurchaser: boolean;
  purchaserOnly: boolean;
  private unsubscribe$ = new Subject<void>();
  poEmpty: boolean = false;
  @ViewChild('purchaseOrderModal') purchaseOrderModal: ElementRef;
  @ViewChild('placedorderModal') placedorderModal: ElementRef;
  @ViewChild('orderPlacedModal') orderPlacedModal: ElementRef;
  @ViewChild('purchaseOrderInput')
  purchaseOrderInput!: ElementRef<HTMLInputElement>;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    public sharedCheckoutService: SharedCheckoutService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private windowRef: WindowRef
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.globalService.loadingSubject.next(true);
        let cartGroupId = params['cartGroupId'];
        this.determineUserRoles();
        if (params['cartGroupId'] && params['review']) {
          this.flag = true;
          this.storageService.paramsCartGroupId = cartGroupId;
          this.apiService.cartgroupOrder(cartGroupId)
            .pipe(
              filter(isNotNullable),
              takeUntil(this.unsubscribe$))
            .subscribe({
              next: (response) => {
                this.sharedCheckoutService.saveCartGroupData(response);
                this.companyName = response?.carts.at(0)?.legalEntity?.company;
                this.addresses = response?.carts?.at(0)?.legalEntity?.addresses?.addresses.find((val) => val.billingAddress === true);
                this.cartlegalEntity = this.addresses?.companyName;
                this.cartpurchaseOrderNumber = response?.carts?.at(0)?.purchaseOrderNumber;
                this.storageService.cartCode = response?.carts?.at(0)?.code;
                this.stopSpinnerBasedOnSmartEdit();
                this.cdr.markForCheck();
              },
              error: (error) => {
                console.error('Error fetching Cart Group Order:', error);
              }
            });
        } else {
          this.initializeComponent();
        }
      });
  }

  async initializeComponent(): Promise<void> {
    this.selectedLegalEntity = this.globalService.defaultLegalEntitySubject$;
    this.selectedLegalEntitySession = this.storageService.defaultLegalEntity
      ? this.storageService.defaultLegalEntity.name
      : '';
    this.sharedCheckoutService.showModal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.showModal());
    this.fetchBillingAddress();
  }

  stopSpinnerBasedOnSmartEdit() {
    if (this.globalService.checkSmartEdit()) {
      this.globalService.loadingSubject.next(false);
    }
  }

  private determineUserRoles() {
    const roles = this.storageService?.userRoles;
    this.requesterOnly =
      roles?.length > 0
        ? roles.includes('Requester') && !roles.includes('Purchaser')
        : false;
    this.requesterAndPurchaser =
      roles?.length > 0
        ? roles.includes('Requester') && roles.includes('Purchaser')
        : false;
    this.purchaserOnly =
      roles?.length > 0
        ? roles.includes('Purchaser') && !roles.includes('Requester')
        : false;
  }

  fetchBillingAddress(): void {
    if (!this.componentActive) return;

    let pageVal = 0;
    let fieldsVal = 'FULL';
    let pageSizeVal = 200;
    let currentUserId = this.globalService.getUserId();
    this.apiService.getLegalEntityList(pageVal, fieldsVal, pageSizeVal, currentUserId)
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (response) => {
          if (response?.legalEntities?.length > 0) {
            this.legalEntityObj = response.legalEntities.find((val) => val.uid === this.storageService?.defaultLegalEntity?.uid);
            this.addresses = this.legalEntityObj?.addresses?.addresses?.find((val) => val.billingAddress === true)
            this.flag = (this.addresses?.companyName) ? false : true;
            this.cdr.markForCheck();
          }
        },
        error: (error) => {
          if (this.componentActive) {
            console.error('Error fetching shipping addresses:', error);
          }
        }
      })
  }

  showModal(): void {
    this.modalInstance = new bootstrap.Modal(
      this.purchaseOrderModal.nativeElement,
      { backdrop: 'static', keyboard: false }
    );
    this.modalInstance.show();
  }

  proceedWithOrder(): void {
    this.purchaseOrderNumber = encodeURIComponent(this.purchaseOrderNumber);
    if (!this.purchaseOrderNumber) {
      this.poEmpty = true;
      return;
    }
    this.modalInstance.hide();
    this.placeOrder();
  }

  //Dont remove any logs or comment
  placeOrder(): void {
    this.purchaseOrderNumber = !this.purchaseOrderNumber
      ? this.purchaseOrderInput.nativeElement.value
      : this.purchaseOrderNumber;
    if (!this.purchaseOrderNumber) {
      console.error('Purchase order number is required.');
      return;
    }
    const cartCode = this.storageService.cartCode || this.storageService.secondaryCartCode;
    if (!cartCode) {
      console.error('No valid cartCode or secondaryCartCode found.');
      return;
    }
    this.globalService.loadingSubject.next(true); // Start spinner
    // Step 1: Trigger placeOrder API
    this.apiService
      .placeOrder(this.purchaseOrderNumber, this.storageService.checkoutCartGroupId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          console.log('Place order successful', response);
          // Step 2: Check user role and non-quotation products for approveOrder trigger
          const roles = this.storageService.userRoles;
          // Condition: Only trigger approveOrder if user is PurchaserOnly or RequesterAndPurchaser AND non-quotation products are present
          const isPurchaserOrRequesterAndPurchaser = roles.includes('Purchaser') || (roles.includes('Requester') && roles.includes('Purchaser'));
          if (isPurchaserOrRequesterAndPurchaser && this.storageService.cartCode) {
            console.log('Triggering approveOrder for Purchaser or RequesterAndPurchaser role with non-quotation products');
            this.apiService
              .approveOrder(this.purchaseOrderNumber, this.storageService.checkoutCartGroupId)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe({
                next: (approveResponse) => {
                  console.log('Approve order successful', approveResponse);
                  this.finalizeOrder();
                },
                error: (error) => {
                  console.error('Error approving order', error);
                  this.globalService.loadingSubject.next(false); // Stop spinner on error
                },
              });
          } else {
            // If no approval is needed (e.g., RequesterOnly role or only quotation products)
            console.log('No approval needed (RequesterOnly role or only quotation products)');
            this.finalizeOrder();
          }
        },
        error: (error) => {
          console.error('Error placing order', error);
          this.globalService.loadingSubject.next(false); // Stop spinner on error
        },
      });
  }

  private finalizeOrder(): void {
    this.globalService.loadingSubject.next(false); // Stop spinner after both actions
    // Show the modal indicating the order has been placed
    this.modalInstance = new bootstrap.Modal(this.orderPlacedModal.nativeElement, {
      backdrop: 'static',
      keyboard: false,
    });
    this.modalInstance.show();
    this.orderPlacedModal.nativeElement.removeEventListener('hide.bs.modal', this.redirectToOrder);
    this.orderPlacedModal.nativeElement.addEventListener('hide.bs.modal', () => {
      this.redirectToOrder();
    });
    this.removeBackdrop();
  }

  redirectToOrder(): void {
    if (this.modalInstance) {
      this.modalInstance.hide();
    }
    this.router.navigate([AppConstants.routeUrls.orderHistory]);
    this.removeBackdrop();
  }

  private removeBackdrop(): void {
    const backdrop = this.windowRef.document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
