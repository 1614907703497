<div class="row">
	<div class="col-12 col-lg-3">
		<label for="company" class="checkout-label">
			{{"checkOut.company" | cxTranslate}}
		</label>
		<p *ngIf="flag === false">
			{{ legalEntityObj?.company }}
		</p>
		<p *ngIf="flag === true">{{companyName }}</p>
		<label for="legalEntity" class="checkout-label">
			{{"checkOut.legalEntity" | cxTranslate}}
		</label>
		<p *ngIf="flag === false" id="legalEntity">
			{{selectedLegalEntitySession.name}}
		</p>
		<p *ngIf="flag === true" id="legalEntity">
			{{ cartlegalEntity }}
		</p>
	</div>
	<div class="col-12 col-lg-3 offset-lg-1">
		<label for="billingAddress" class="checkout-label">
			{{"checkOut.billingAddress" | cxTranslate}}
		</label>
		<div id="billingAddress" class="mb-3">
			<ng-container *ngIf="addresses && flag === false">
				<div class="mb-1">{{addresses?.companyName}}</div>
				<div class="mb-1">{{ addresses.line1 }}</div>
				<div class="mb-1">{{ addresses.line2 }}</div>
				<div class="mb-1">{{ addresses.postalCode }}, {{ addresses.town }}, {{ addresses.region?.name }}</div>
				<div class="mb-1">{{ addresses.country?.name }}</div>
			</ng-container>
			<ng-container *ngIf="addresses && flag === true">
				<div class="mb-1">{{cartlegalEntity}}</div>
				<div class="mb-1">{{ addresses.line1 }}</div>
				<div class="mb-1">{{ addresses.line2 }}</div>
				<div class="mb-1">{{ addresses.postalCode }}, {{ addresses.town }}, {{ addresses.region?.name }}</div>
				<div class="mb-1">{{ addresses.country?.name }}</div>
			</ng-container>
		</div>
	</div>
	<div class="col-12 col-lg-3 offset-lg-1 form-group">
		<label for="purchaseOrderNumber" class="checkout-label">
			{{"checkOut.purchaseNo" | cxTranslate}}
		</label>
		<div *ngIf="flag === false">
			<input #textInput [(ngModel)]="purchaseOrderNumber" id="purchaseOrderNumber" type="text"
				class="form-control rounded-0">
		</div>
		<div *ngIf="flag === true">
			<input [(ngModel)]="cartpurchaseOrderNumber" id="purchaseOrderNumber" type="text"
				class="form-control rounded-0">
		</div>
	</div>
</div>
<div #purchaseOrderModal class="modal fade" tabindex="-1" id="purchaseOrderModal" aria-labelledby="purchaseOrderModal"
	aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{"checkOut.purchaseOrderNoMissing"|cxTranslate}}
				</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<fieldset class="form-group">
					<input [(ngModel)]="purchaseOrderNumber"
						[style]="{'border': poEmpty ? '1px solid red' : '1px solid #ced4da'}" id="purchaseOrderNumber"
						id="company-input" type="text" placeholder="{{'checkOut.purchaseOrderNO'|cxTranslate}}" required
						class="form-control rounded-0">
				</fieldset>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button type="button" class="btn cstm-btn-light mb-2 ml-2" data-dismiss="modal">
					{{"checkOut.cancel"|cxTranslate}}
				</button>
				<button (click)="proceedWithOrder()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
					{{"checkOut.proceed"|cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>
<div #placedorderModal class="modal fade" tabindex="-1" id="placedorderModal" aria-labelledby="placedorderModal"
	aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{"checkOut.orderPlaced"|cxTranslate}}
				</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<span>
					{{"checkOut.orderHasBeenPlaced"|cxTranslate}}
					{{"checkOut.itMightTakeTimeToBeRegisteredInSystem"|cxTranslate}}
				</span>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button type="button" class="btn cstm-btn-primary mb-2 ml-2" data-dismiss="modal">
					{{"checkOut.ok"|cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>
<div #orderPlacedModal class="modal fade" tabindex="-1" id="orderPlacedModal" aria-labelledby="orderPlacedModal"
	aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{"checkOut.orderPlaced"|cxTranslate}}
				</h4>
				<button (click)="redirectToOrder()" type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<span *ngIf="requesterOnly">
					{{"checkOut.orderRequestHasBeenPlaced"|cxTranslate}}
				</span>
				<span *ngIf="requesterAndPurchaser || purchaserOnly">
					{{"checkOut.orderHasBeenPlaced"|cxTranslate}}
					{{"checkOut.itMightTakeTimeToBeRegisteredInSystem"|cxTranslate}}
				</span>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button (click)="redirectToOrder()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
					{{"checkOut.ok"|cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>